import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const HomePageHeader = props => {
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        kafkaSummitLogo {
          file {
            url
          }
        }
        kafkaSummitWhiteLogo {
          file {
            url
          }
        }
      }
    }
  `)

  const [menustate, setmenustate] = useState({ menu: false })

  const mobnavmenuopen = () => {
    let menuopen = document.getElementById("header_menu")
    if (!menustate.menu) {
      setmenustate({ menu: true })
      menuopen.className += " header_menu_close"
    } else {
      setmenustate({ menu: false })
      menuopen.className = "header_menu"
    }
  }

  return (
    <div
      className={
        props?.transparentHeader
          ? "header_wrap transparent_header"
          : "header_wrap"
      }
    >
      <div className="header_logo">
        <Link to="/">
          <img
            src={
              props?.color === "white"
                ? data?.contentfulHomePage?.kafkaSummitWhiteLogo?.file?.url
                : data?.contentfulHomePage?.kafkaSummitLogo?.file?.url
            }
            alt="logo"
          />
        </Link>
      </div>
      <div className="mobile_nav_btn">
        <button className="mobile_nav_btn_grp" onClick={mobnavmenuopen}>
          <div
            className="mobile_nav_btn_bar"
            style={
              menustate.menu
                ? {
                    transform: "rotateZ(45deg)",
                    marginBottom: "0px",
                    transformOrigin: "center",
                  }
                : { transform: "rotateZ(0deg)" }
            }
          ></div>
          <div
            className="mobile_nav_btn_bar"
            style={
              menustate.menu
                ? {
                    transform: "rotateZ(-45deg)",
                    marginBottom: "8px",
                    transformOrigin: "center",
                  }
                : { transform: "rotateZ(0deg)" }
            }
          ></div>
          <div
            className="mobile_nav_btn_bar"
            style={menustate.menu ? { display: "none" } : { display: "block" }}
          ></div>
        </button>
      </div>
      <div className="header_menu" id="header_menu">
        <ul className="mobile_nav_list">
          <li>
            <Link to="#">
              <span className={props.color === "white" ? "color_white" : ""}>
                About
              </span>
              <span></span>
            </Link>
            <ul>
              <div className="triangle-up"></div>
              <li>
                <Link to={`/about-kafka`}>About Apache Kafka</Link>
              </li>
              <li>
                <Link to={`/program-committee`}>Program Committee</Link>
              </li>
              <li>
                <Link to={`/code-of-conduct`}>Code of Conduct</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link
              to={`/past-events`}
              className={props.color === "white" ? "color_white" : ""}
            >
              Past Events
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HomePageHeader
