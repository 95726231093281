import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Head from "../components/head"
import PageLayout from "../components/apcpagelayout"

const CodeOfConduct = () => {
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
  })
  const data = useStaticQuery(graphql`
    query {
      contentfulHomePage {
        title
        bannerBackground {
          file {
            url
          }
        }
      }
    }
  `)
  return (
    <PageLayout
      title="CODE OF CONDUCT"
      backgroundImage={data?.contentfulHomePage?.bannerBackground}
    >
      <Head
        title="Kafka Summit Code of Conduct"
        description="Kafka Summit Code of Conduct  - Confluent is dedicated to providing the best Kafka Summit conference experience possible for the Apache Kafka® Community. Our goal is to provide the opportunity for conference participants (including speakers, sponsors, staff or guests) to learn, communicate, contribute, and collaborate. As such, we are committed to creating a harassment-free experience for everyone, regardless of gender, sexual orientation, disability, physical appearance, body size, race, or religion. We do not tolerate harassment of conference participants in any form. Any form of written, social, or verbal communication that can be offensive or harassing to any attendee, speaker or staff is not allowed at Kafka Summit. Conference participants violating these rules may be sanctioned or expelled from the conference without a refund at the discretion of the conference organizers. - Date TBD - Location TBD"
      />
      <div className="about_kafka_body">
        <div
          className="about_kafka_body_data"
          // style={{ whiteSpace: "pre-wrap" }}
        >
          <p>
            Confluent is dedicated to providing the best Kafka Summit conference
            experience possible for the Apache Kafka® Community. Our goal is to
            provide the opportunity for conference participants (including
            speakers, sponsors, staff or guests) to learn, communicate,
            contribute, and collaborate.{" "}
          </p>
          <br />
          <p>
            As such, we are committed to creating a harassment-free experience
            for everyone, regardless of gender, sexual orientation, disability,
            physical appearance, body size, race, or religion. We do not
            tolerate harassment of conference participants in any form. Any form
            of written, social, or verbal communication that can be offensive or
            harassing to any attendee, speaker or staff is not allowed at Kafka
            Summit. Conference participants violating these rules may be
            sanctioned or expelled from the conference without a refund at the
            discretion of the conference organizers.
          </p>
        </div>
      </div>
    </PageLayout>
  )
}
export default CodeOfConduct
