import React, { useEffect } from "react"
import "../styles/index.scss"
import Header from "./homepageheader"
import Banner from "./banner-apc"

import Footer from "./footer"

const ApcPageLayout = props => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = window.location.search
      if (!params) {
        const storedParams = sessionStorage.getItem("params")
        if (storedParams) {
          window.history.replaceState(
            {},
            "",
            window.location.href + storedParams
          )
        }
        return
      }
      sessionStorage.setItem("params", params)
    }
  }, [])

  return (
    <>
      <div className="main_wrap">
        <div className="container">
          <div className="homepage_header">
            <img
              src={props?.backgroundImage?.file?.url}
              className="banner_background"
            />
            <div className="banner_data">
              <Header color="white" />
              <Banner title={props.title} date={props.date} />
            </div>
          </div>

          {props.children}
          <Footer />
        </div>
      </div>
    </>
  )
}

export default ApcPageLayout
