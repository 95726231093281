import React from "react"

const Banner = props => {
  return (
    <div className="banner_main2">
      <div className="banner_ct_data">
        <h1 className="banner_title">{props.title}</h1>
        <p className="banner_date">{props.date}</p>
      </div>
    </div>
  )
}
export default Banner
